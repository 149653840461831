<template>
  <div class="fill-height">
    <v-dialog width="600" height="400" v-model="alphaDialog">
      <v-card>
        <v-card-title>Welcome to VNC v2!</v-card-title>
        <v-card-text>This system is in <span class="font-weight-bold">ALPHA</span> phase at the moment. All bugs should be reported to @sunjic.marijo on Slack!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="alphaDialog = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-container fluid class="fill-height">
    <v-row align-v="center" align-h="center" class="d-flex align-center justify-center flex-column">
      <img src="../assets/logo.png" class="nsoft-logo" />
      <v-btn color="primary" outlined class="mt-4" size="" :disabled="transitioning || loading || !currentConnection.uuid" @click="initConnection">Connect</v-btn>
      <div class="loading-info text-center mt-4" v-if="loading">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <h1 class="title mt-2">{{ loading ? 'Please wait' : (!currentConnection.uuid) ? 'Error' : 'Ready'}}</h1>
      <span class="subtitle-1">{{ loading ? 'We are fetching your connection info' : (!currentConnection.uuid) ? 'Connection not found. Re-initialize through 7Admin' : '' }}</span>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
export default {
  data: () => ({
    alphaDialog: false,
    transitioning: false
  }),
  computed: {
    ...mapGetters({"loading": "getLoading", "currentConnection": "getCurrentConnection"})
  },
  methods: {
    ...mapActions({"getConnectionInfo": "getConnectionInfo", "initCurrentConnection": "initCurrentConnection"}),
    initConnection() {
      this.initCurrentConnection(this.$route.query.password).then((response) => {
        if (response.success) {
          this.transitioning = true
          setTimeout(() => {
            this.$router.push("/vnc");
          }, 1000);
        }
      })
    }
  },
  mounted() {
    // this.alphaDialog = true;
    const { connectionUuid } = this.$route.query
    this.getConnectionInfo(connectionUuid);
  }

}
</script>

<style lang="scss" scoped>
.nsoft-logo {
  max-width: 264px;
}
</style>
